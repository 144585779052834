import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'redux/store';
import { BarragemF, SimulationDetailF, SimulationF, UsuarioF } from 'types';
import { Mutex } from 'async-mutex';
import { loggedOut, setUserLogged } from '../auth/auth-slice';
import config from 'config';
import { UserCreate, UserUpdate } from './types/usuario';
import { PasswordChange } from './types/account';
import { SimulacaoUpdate } from './types/simulacao';

export interface LoginResponse {
    refresh: string;
    access: string;
}

export interface LoginRequest {
    email: string;
    password: string;
}

const baseQuery = fetchBaseQuery({
    baseUrl: config.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).Auth.access;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery(
                    {
                        url: '/refresh',
                        method: 'POST',
                        body: {
                            refresh: (api.getState() as RootState).Auth.refresh,
                        },
                    },
                    api,
                    extraOptions
                );
                if (refreshResult.data) {
                    api.dispatch(setUserLogged(refreshResult.data as LoginResponse));
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(loggedOut());
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};
const allTags = ['User', 'Profile', 'Asset', 'Geo'];
export const serviceApi = createApi({
    reducerPath: 'serviceApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: allTags,
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (credentials) => ({
                url: 'account/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: allTags,
        }),
        forgotPassword: builder.mutation<undefined, string>({
            query: (email) => ({
                url: 'account/forgotPassword',
                method: 'POST',
                body: {
                    email,
                },
            }),
        }),
        logout: builder.mutation<undefined, undefined>({
            query: () => ({
                url: 'account/logout',
                method: 'POST',
            }),
            invalidatesTags: allTags,
        }),
        profile: builder.query<UsuarioF, undefined>({
            query: () => ({ url: 'account/profile' }),
            providesTags: ['Profile'],
        }),
        changePassword: builder.mutation<undefined, PasswordChange>({
            query: ({ confirmPassword, old_password, password }) => ({
                url: 'account/change_pass',
                method: 'POST',
                body: {
                    password,
                    old_password,
                    confirmPassword,
                },
            }),
        }),
        updateUser: builder.mutation<UsuarioF, UserUpdate>({
            query: ({ id, ...data }) => ({
                url: `users/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['User', 'Profile'],
        }),
        getUsers: builder.query<UsuarioF[], undefined>({
            query: () => ({ url: 'users' }),
            providesTags: ['User'],
        }),
        getUser: builder.query<UsuarioF, UsuarioF['id']>({
            query: (id) => ({ url: `users/${id}` }),
            providesTags: ['User'],
        }),
        removeUser: builder.mutation<undefined, UsuarioF['id']>({
            query: (id) => ({
                url: `users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
        createUser: builder.mutation<UsuarioF, UserCreate>({
            query: (data) => ({
                url: 'users',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
        getAssets: builder.query<BarragemF[], undefined>({
            query: () => ({ url: 'asset' }),
            providesTags: ['Asset'],
        }),
        getAsset: builder.query<BarragemF, BarragemF['id']>({
            query: (id) => ({ url: `asset/${id}` }),
            providesTags: ['Asset'],
        }),
        getSimulations: builder.query<SimulationF[], number | undefined>({
            query: (assetId) => {
                let url = 'simulation';
                if (assetId !== undefined) url += `?assetId=${assetId}`;
                return url;
            },
            providesTags: ['Geo'],
        }),
        createSimulation: builder.mutation<SimulationF, any>({
            query: ({ assetId, ...data }) => ({
                url: `asset/${assetId}/simulate`,
                method: 'POST',
                body: {
                    ...data,
                },
            }),
            invalidatesTags: ['Geo'],
        }),
        getSimulation: builder.query<SimulationDetailF, SimulationF['id']>({
            query: (id) => `simulation/${id}`,
            providesTags: ['Geo'],
        }),
        updateSimulation: builder.mutation<SimulationF, SimulacaoUpdate>({
            query: ({ id, name }) => ({
                url: `simulation/${id}`,
                method: 'PATCH',
                body: {
                    name,
                },
            }),
            invalidatesTags: ['Geo'],
        }),
        removeSimulation: builder.mutation<undefined, SimulationF['id']>({
            query: (id) => ({
                url: `simulation/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Geo'],
        }),
    }),
});

export const {
    useRemoveSimulationMutation,
    useUpdateSimulationMutation,
    useCreateSimulationMutation,
    useChangePasswordMutation,
    useForgotPasswordMutation,
    useGetSimulationsQuery,
    useGetSimulationQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useRemoveUserMutation,
    useGetAssetsQuery,
    useLogoutMutation,
    useGetAssetQuery,
    useLoginMutation,
    useGetUsersQuery,
    useProfileQuery,
    useGetUserQuery,
} = serviceApi;
